import React, { useState } from "react";
import "./FAQ.css";
import { useEffect } from "react";

const FAQ = () => {
  const [question1, setQuestion1] = useState(false);
  const [question2, setQuestion2] = useState(false);
  const [question3, setQuestion3] = useState(false);
  const [question4, setQuestion4] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const viewBox = screenWidth < 497 ? "0 0 21 23" : "0 0 19.7 21";

  const svg_plus = `<svg
  width="110px"
  height="110px"
  viewBox="${viewBox}"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
  <g
    id="SVGRepo_tracerCarrier"
    stroke-linecap="round"
    stroke-linejoin="round"
  ></g>
  <g id="SVGRepo_iconCarrier">
    {" "}
    <path
      d="M4 12H20M12 4V20"
      stroke="#ffffff"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>{" "}
  </g>
</svg>`;

  return (
    <div className="faq_wrapper">
      <div className="faq_heading">
        Frequently Asked Questions
      </div>
      <div className="faq_content-wrapper">
        <div
          data-w-id="b96b4542-3047-72a1-60b1-fa93b61cb138"
          className="accordion-item-wrapper v6"
        >
          <div className="accordion-content-wrapper width-660px">
            <div className="accordion-header">
              <h3 className="accordion-title">Who is the trainer?</h3>
            </div>
            <div
              style={{
                height: question1 ? "190px" : "0px",
                transition: "height 0.3s",
                WebkitTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: question1 ? "" : 0,
              }}
              className="acordion-body"
            >
              <div className="accordion-spacer"></div>
              <p className="accordion_content">
                Ashfaq ahmed, he is the founder of RecruitingMonk. He has
                trained/consulted more than 10,000+ Recruiters across
                corporates, startups and agencies. He writes on linkedin
                everyday about TA. Visit his linkedin profile{" "}
                <a
                  href="https://www.linkedin.com/in/ashfaqahmedhr/"
                  target="_blank"
                  style={{ color: "#e33843" }}
                >
                  right here
                </a>
              </p>
            </div>
          </div>
          <div className="accordion-side right-side">
            <div
              onClick={() => setQuestion1(!question1)}
              className={`btn-circle-secondary small accordion-btn w-inline-block ${
                question1 ? "clicked" : ""
              }`}
              style={{ backgroundColor: question1 ? "#e33843" : "" }}
            >
              <div dangerouslySetInnerHTML={{ __html: svg_plus }}></div>
              {/* <div className="accordion-btn-line vertical"></div>
                    <div className="accordion-btn-line horizontal"></div> */}
            </div>
          </div>
        </div>
        <div
          data-w-id="09ecc7fb-c901-1ca9-b971-0c6b7af7d86c"
          className="accordion-item-wrapper v6"
        >
          <div className="accordion-content-wrapper width-660px">
            <div className="accordion-header">
              <h3 className="accordion-title">Is it a live session?</h3>
            </div>
            <div
              style={{
                height: question2 ? "120px" : "0px",
                transition: "height 0.3s",
                WebkitTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: question2 ? "" : 0,
              }}
              className="acordion-body"
            >
              <div className="accordion-spacer"></div>
              <p className="accordion_content">
                It is a combination of live + Recorded. Every week you will have
                one live session with Ashfaq. You’d get videos/task/assignments
                to be completed everyweek at your own flexible time zones.
              </p>
            </div>
          </div>
          <div className="accordion-side right-side">
            <div
              onClick={() => setQuestion2(!question2)}
              className={`btn-circle-secondary small accordion-btn w-inline-block ${
                question2 ? "clicked" : ""
              }`}
              style={{ backgroundColor: question2 ? "#e33843" : "" }}
            >
              <div dangerouslySetInnerHTML={{ __html: svg_plus }}></div>
            </div>
          </div>
        </div>
        <div
          data-w-id="ce07f836-e054-9e92-ac41-907507bcce09"
          className="accordion-item-wrapper v6"
        >
          <div className="accordion-content-wrapper width-660px">
            <div className="accordion-header">
              <h3 className="accordion-title">
                Will I get a certificate after the session?
              </h3>
            </div>
            <div
              style={{
                height: question3 ? "120px" : "0px",
                transition: "height 0.3s",
                WebkitTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: question3 ? "" : 0,
              }}
              className="acordion-body"
            >
              <div className="accordion-spacer"></div>
              <p className="accordion_content">
                Yes, after completing the session, you will get a certificate.
              </p>
            </div>
          </div>
          <div className="accordion-side right-side">
            <div
              onClick={() => setQuestion3(!question3)}
              className={`btn-circle-secondary small accordion-btn w-inline-block ${
                question3 ? "clicked" : ""
              }`}
              style={{ backgroundColor: question3 ? "#e33843" : "" }}
            >
              <div dangerouslySetInnerHTML={{ __html: svg_plus }}></div>
            </div>
          </div>
        </div>
        <div
          data-w-id="cc25f66e-4f9a-3d0e-0861-7522ce979ea7"
          className="accordion-item-wrapper v6"
        >
          <div className="accordion-content-wrapper width-660px">
            <div className="accordion-header">
              <h3 className="accordion-title">Who can attend this?</h3>
            </div>
            <div
              style={{
                height: question4 ? "120px" : "0px",
                transition: "height 0.3s",
                WebkitTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 20px, 0) scale3d(0.96, 0.96, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                opacity: question4 ? "" : 0,
              }}
              className="acordion-body"
            >
              <div className="accordion-spacer"></div>
              <p className="accordion_content">
                Anyone with atleast one year of experience in Talent Acquisition
              </p>
            </div>
          </div>
          <div className="accordion-side right-side">
            <div
              onClick={() => setQuestion4(!question4)}
              className={`btn-circle-secondary small accordion-btn w-inline-block ${
                question4 ? "clicked" : ""
              }`}
              style={{ backgroundColor: question4 ? "#e33843" : "" }}
            >
              <div dangerouslySetInnerHTML={{ __html: svg_plus }}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
