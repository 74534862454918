import React from "react";
import "./WorkshopFor.css";

const WorkshopFor = () => {
  return (
    <div className="workshop_container">
      <div className="worshop_container_heading">Who is this workshop for?</div>

      <div className="workshop__container__contents">
        <div className="worshop_container_content">
          <div>
            <svg
              height="800"
              width="800"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                fill="#ad2831"
                d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32zm-2 23-8-7 3-3 5 5 9-10 3 3-12 12z"
              />
            </svg>
          </div>
          <div className="workshop__container_contents_heading">
            1 - 20 years of <span className="workshop__color"> experience </span>in
            tech hiring
          </div>
        </div>

        <div className="worshop_container_content">
          <div>
            <svg
              height="800"
              width="800"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                fill="#ad2831"
                d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32zm-2 23-8-7 3-3 5 5 9-10 3 3-12 12z"
              />
            </svg>
          </div>

          <div className="workshop__container_contents_heading">
            Recruiters looking to up
            their <span className="workshop__color"> sourcing game. </span>
          </div>
        </div>

        <div className="worshop_container_content">
          <div>
            <svg
              height="800"
              width="800"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                fill="#ad2831"
                d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32zm-2 23-8-7 3-3 5 5 9-10 3 3-12 12z"
              />
            </svg>
          </div>

          <div className="workshop__container_contents_heading">
            <span className="workshop__color"> TA professionals </span>who want
            to leverage storytelling approaches
          </div>
        </div>

        <div className="worshop_container_content">
          <div>
            <svg
              height="800"
              width="800"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                fill="#ad2831"
                d="M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32zm-2 23-8-7 3-3 5 5 9-10 3 3-12 12z"
              />
            </svg>
          </div>

          <div className="workshop__container_contents_heading">
            Managers wanting to <span className="workshop__color"> learn </span>
            better talent intel practices
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkshopFor;
