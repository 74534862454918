import React from "react";
import "./HeaderCourse.css";

const HeaderCourse = () => {
  return (
    <>
      <div className="header_container" style={{ backgroundImage: `url(/images/bg.svg)`}}>
        <div className="header_heading">
          Mini Diploma for <br /> TA professionals
        </div>
        <div className="header_subHeading">
          A 12 week intensive learning crafted for experienced TA professionals{" "}
          <br />
          in the tech hiring space.
        </div>

        <div className="header_course_details">
          <div className="course_date">
            <div className="courseDetails">
              <div className="course_info">Application deadline</div>
              <div className="course_subInfo">30th June 2024</div>
            </div>
            <div className="courseDetails">
              <div className="course_info">Course starts on</div>
              <div className="course_subInfo">6th July 2024</div>
            </div>
          </div>
        </div>

        <div
          className="apply_button"
          onClick={() => window.open("https://wa.me/+917416266999", "_blank")}
        >
          Apply Now
        </div>

        <div className="apply_Message">
          The cohort is limited to 12 people per Batch. <br /> Hurry up! Join now.
        </div>
      </div>
    </>
  );
};

export default HeaderCourse;
