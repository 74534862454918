import React from "react";
import "./CheckKnowledge.css";

const CheckKnowledge = () => {
  return (
    <>
      <div className="checkknwoledge_container" style={{ backgroundImage: `url(/images/bg.svg)`}}>
        <div className="header_heading">
          Not sure about picking this course?
        </div>

        <div className="header_subHeading">
          Test. your sourcing skills, candidate engagement skills, probing
          skills and more. Know where you stand in comparison to 1000 of other
          Recruiters out there.
        </div>

        <div className="apply_button checkknowledegebutton" onClick={() => window.open("http://quiz.recruitingmonk.com", "_blank")}>Take Quiz</div>
      </div>
    </>
  );
};

export default CheckKnowledge;
