import React from "react";
import "./Brands.css";

const Brands = () => {
  return (
    <div className="brands__container">
      <div className="brands__heading">
        Our candidates work at these Big Brands
      </div>
      <div className="brands__tags_container">
        <div className="brands__tags">
          <img src="/images/company1.png" alt="" />
        </div>
        <div className="brands__tags">
          <img src="/images/company1.svg" alt="" />
        </div>
        <div className="brands__tags">
          <img src="/images/company2.jpg" alt="" />
        </div>
        <div className="brands__tags">
          <img src="/images/company3.jpg" alt="" />
        </div>
        <div className="brands__tags">
          <img src="/images/company4.jpg" alt="" />
        </div>
        <div className="brands__tags">
          <img src="/images/company5.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Brands;
