import React from "react";
import "./LatestMentors.css";

const LatestMentors = () => {
  return (
    <div className="latestmentors_containers">
      <div className="latestmentors_heading">Previous Guest Speakers</div>
      <div className="latestmentor_cards">
        <div className="latestmentors_card">
          <img
            src="/images/mentor1.webp"
            alt=""
            className="latestmentor_image"
          />
          <div className="latestmentors_content">
            <div className="latestmentors_content_heading">
              <div className="latestmentor_name">Sarang Brahme</div>
              <div className="latestmentors_position">
                Head of Employer (Talent) Branding{" "}
              </div>
            </div>
            <img src="/images/mentor1company.jpeg" alt="" width="150px" />
          </div>
        </div>

        <div className="latestmentors_card">
          <img
            src="/images/mentor2.webp"
            alt=""
            className="latestmentor_image"
          />
          <div className="latestmentors_content">
            <div className="latestmentors_content_heading">
              <div className="latestmentor_name">Raghunandan Patre</div>
              <div className="latestmentors_position">Cofounder</div>
            </div>
            <img src="/images/mentor2company.jpeg" alt="" width="90px" />
          </div>
        </div>

        <div className="latestmentors_card">
          <img
            src="/images/mentor3.png"
            alt=""
            className="latestmentor_image"
          />
          <div className="latestmentors_content">
            <div className="latestmentors_content_heading">
              <div className="latestmentor_name">Linish Theodore</div>
              <div className="latestmentors_position">
                AVP at Connect & Heal
              </div>
            </div>
            <img src="/images/mentor3_company.jpeg" alt="" width="45px" />
          </div>
        </div>

        <div className="latestmentors_card">
          <img
            src="/images/mentor4.png"
            alt=""
            className="latestmentor_image"
          />
          <div className="latestmentors_content">
            <div className="latestmentors_content_heading">
              <div className="latestmentor_name">Savita Hortikar</div>
              <div className="latestmentors_position">
                Global TA Head - Fractal Analytics
              </div>
            </div>
            <img src="/images/mentor4_company.jpeg" alt="" width="60px" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestMentors;
