import React from "react";
import "./YourTrainer.css";

const YourTrainer = () => {
  return (
    <div className="yourtrainer_container" style={{ backgroundImage: `url(/images/bg.svg)`}}>
      <div className="yourtrainer_heading">Meet Your Trainer</div>

      <div className="yourtrainer_content">
        <img src="/images/ashfaq_banner.png" className="ashfaq_image" alt="" />
        <div className="yourtrainer_subcontent">
          <div
            className="yourtraier_subconent_mainheading"
          >
            <div className="yourtrainer_subcontent_heading">
              <div className="yourtrainer_subcontent_heading_name">
                Ashfaq Ahmed
              </div>
              <a
                href="https://www.linkedin.com/in/ashfaqahmedhr/"
                target="_blank"
              >
                <svg
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                >
                  <path
                    fill="#0A66C2"
                    d="M12.225 12.225h-1.778V9.44c0-.664-.012-1.519-.925-1.519-.926 0-1.068.724-1.068 1.47v2.834H6.676V6.498h1.707v.783h.024c.348-.594.996-.95 1.684-.925 1.802 0 2.135 1.185 2.135 2.728l-.001 3.14zM4.67 5.715a1.037 1.037 0 0 1-1.032-1.031c0-.566.466-1.032 1.032-1.032.566 0 1.031.466 1.032 1.032 0 .566-.466 1.032-1.032 1.032zm.889 6.51h-1.78V6.498h1.78v5.727zM13.11 2H2.885A.88.88 0 0 0 2 2.866v10.268a.88.88 0 0 0 .885.866h10.226a.882.882 0 0 0 .889-.866V2.865a.88.88 0 0 0-.889-.864z"
                  />
                </svg>
              </a>
            </div>
            <div className="yourtrainer_subcontent_subheading">
              Founder & Head Monk @ Recruiting Monk
            </div>
          </div>

          <div className="yourtrainer_subcontent_content">
            Ashfaq is an Engineer by Chance, but a Recruiter by choice. He comes
            with about 8+ years of experience in Recruitment, out of which 7yrs
            have been in training & nurturing Recruiters. He has trained close
            to 10,000 Tech Recruiters. He is the founder of Recruiting Monk and
            in his current role he wears multiple hats like Product Manager,
            Trainer, Consultant etc.
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourTrainer;
