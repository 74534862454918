import React from "react";
import "./LearningInPrograme.css";

const LearningInPrograme = () => {
  return (
    <div className="learning_container">
      <div className="learning_heading">
        What you will Learn in this program?
      </div>
      <div className="learningContent">
        <div className="learnContainer">
          <div className="learncontainer_module">
            <p>MODULE 1</p>
          </div>
          <div className="learningContainer_content">
            Learn the Personas of top 8-10 digital tech roles.
          </div>
          <div>
            <img
              src="/images/svg//1-col.svg"
              alt=""
              className="learnContainer_img"
            />
          </div>
        </div>
        <div className="learnContainer">
          <div className="learncontainer_module">
            <p>MODULE 2</p>
          </div>
          <div className="learningContainer_content">
            Sourcing errs committed by 99% Recruiters & how to avoid them.
          </div>
          <div>
            <img
              src="/images/svg//2-col.svg"
              alt=""
              className="learnContainer_img"
            />
          </div>
        </div>
        <div className="learnContainer">
          <div className="learncontainer_module">
            <p>MODULE 3</p>
          </div>
          <div className="learningContainer_content">
            The art of implementing bigdata concepts into sourcing
          </div>

          <div>
            <img
              src="/images/svg//3-col.svg"
              alt=""
              className="learnContainer_img"
            />
          </div>
        </div>
        <div className="learnContainer">
          <div className="learncontainer_module">
            <p>MODULE 4</p>
          </div>
          <div className="learningContainer_content">
            Leverage the power of storytelling in your hiring process
          </div>

          <div>
            <img
              src="/images/svg//4-col.svg"
              alt=""
              className="learnContainer_img"
            />
          </div>
        </div>

        <div className="learnContainer">
          <div className="learncontainer_module">
            <p>MODULE 5</p>
          </div>
          <div className="learningContainer_content">
            Engage : How to engage with candidates and HMs, leaving an ever
            lasting experience.
          </div>
          <div>
            <img
              src="/images/svg//5-col.svg"
              className="learnContainer_img"
              alt=""
            />
          </div>
        </div>

        <div className="learnContainer">
          <div className="learncontainer_module">
            <p>MODULE 6</p>
          </div>
          <div className="learningContainer_content">
            Learn to set talent intel frameworks from scratch.
          </div>
          <div>
            <img
              src="/images/svg//6-col.svg"
              className="learnContainer_img"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearningInPrograme;
