import React, { useRef, useState } from "react";
import "./VideoTestimonials.css";
import ReactPlayer from "react-player";

const VideoTestimonials = () => {
  const videoUrl = "https://vimeo.com/857044868/b4c790daf8";
  const videoUrl2 = "https://vimeo.com/857044983/7e6f920ac8";
  const videoUrl3 = "https://vimeo.com/857045125/2d1e0fcdc0";


  return (
    <div className="videotestimonial_container">
      <div className="videotestimonial_heading">Video Testimonials</div>

      <div className="videotestimonial_cards_container">
        <div className="videotestimonial_cards">
          <ReactPlayer url={videoUrl} controls width="250px" height="200px"/>

          <div className="videotestimonial_card_content">
            <div className="videotestimonial_card_heading">
              <div>Shika Abrol</div>
              <p>Associate Manager, TA</p>
            </div>

            <div className="videotestimonial_company">
              <img src="/images/testimonials/videotestimonalbrand3.png" alt="TA Digital"/>
            </div>
          </div>
        </div>

        <div className="videotestimonial_cards">
          <ReactPlayer url={videoUrl2} controls width="250px" height="200px"/>

          <div className="videotestimonial_card_content">
            <div className="videotestimonial_card_heading">
              <div>Komal Choudhari</div>
              <p>Manager, TA</p>
            </div>

            <div className="videotestimonial_company2">
              <img src="/images/testimonials/videotestimonalbrand2.svg" alt=""/>
            </div>
          </div>
        </div>

        <div className="videotestimonial_cards">
          <ReactPlayer url={videoUrl3} controls width="250px" height="200px"/>

          <div className="videotestimonial_card_content">
            <div className="videotestimonial_card_heading">
              <div>Animesh Prasad</div>
              <p>Lead, TA</p>
            </div>

            <div className="videotestimonial_company">
              <img src="/images/testimonials/videotestimonalbrand1.webp" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoTestimonials;
