import React from "react";
import "./StudentTestimonials.css";

const StudentTestimonials = () => {
  const svg = `<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none"><<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none">
  <defs>
    <linearGradient id="grad" x1="0%" y1="0%" x2="100%" y2="30%">
      <stop offset="0%" style="stop-color:#c7c7c7;stop-opacity:1" />
      <stop offset="100%" style="stop-color:#ffffff;stop-opacity:1" />
    </linearGradient>
  </defs>
  <path fill="url(#grad)" d="M8 3a5 5 0 1 0 0 10A5 5 0 0 0 8 3z" />
</svg> fill="#fbdede" d="M8 3a5 5 0 1 0 0 10A5 5 0 0 0 8 3z"/></svg>`;

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const viewportWidth = window.innerWidth;
      const sectionRect = section.getBoundingClientRect();

      // Calculate the desired scroll position to center the card
      const scrollX =
        sectionRect.left + sectionRect.width / 2 - viewportWidth / 2;

      section.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start", // This ensures horizontal scrolling
        scrollLeft: scrollX, // Scroll only in the X-axis
      });
    }
  };

  return (
    <div className="studenttestimonial_container">
      <div className="studenttestimonial_heading">Student Testimonials</div>

      <div className="studenttestimonials">
        <div className="studenttestimmonial_carrousel">
          <div className="studenttestimonial_card" id="1">
            <div className="studenttestimonial_card_header">
              <img src="/images/testimonials/testimonial1a.png" alt="" />
              <div className="studenttestimonial_card_subheader">
                <div className="studenttestimonial_card_name">
                  Kritika Choudhary
                </div>
                <div className="studenttestimonial_card_company">
                  Technical Recruiter <br /> @Google
                </div>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/in/ikritikachoudhary/"
                  target="_blank"
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="-5.5 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#0A66C2"
                      d="M0 8v16c0 1 1 2 3 2h17l1-2V8c0-1-1-2-3-2H1L0 8zm3 2 2-2 2 2-2 2-2-2zm5 13V13h3v1l3-1c2 0 4 1 4 4v7h-3l-1-1v-6l-1-1-2 2v6H8v-1zm-5 0V13h3v11H3v-1z"
                    />
                  </svg>
                </a>
              </div>
            </div>

            <div className="studenttestimonial_card_content">
              "I consider myself fortunate to be able to learn from you and your
              experiences. Your sessions have helped me to make it beyond what I
              imagined myself capable of achieving. I cant thank you enough."
            </div>
          </div>

          <div className="studenttestimonial_card" id="2">
            <div className="studenttestimonial_card_header">
              <img src="/images/testimonials/testimonial2.jpg" alt="" />
              <div className="studenttestimonial_card_subheader">
                <div className="studenttestimonial_card_name">Beulah</div>
                <div className="studenttestimonial_card_company">
                  Head of Recruitment
                  <br /> @Napses Technologies
                </div>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/in/buela-pauline-a1601919/"
                  target="_blank"
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="-5.5 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#0A66C2"
                      d="M0 8v16c0 1 1 2 3 2h17l1-2V8c0-1-1-2-3-2H1L0 8zm3 2 2-2 2 2-2 2-2-2zm5 13V13h3v1l3-1c2 0 4 1 4 4v7h-3l-1-1v-6l-1-1-2 2v6H8v-1zm-5 0V13h3v11H3v-1z"
                    />
                  </svg>
                </a>
              </div>
            </div>

            <div className="studenttestimonial_card_content">
              "Very fortunate to be part of the extraordinary people at
              RecruitingMonk.. Thanks Ashfaq Ahmed for your detailed info and
              you guys are superb when it comes to executing the classes.
              Yaswanth Lohar Veeravalli is a great person who coordinated well
              with all the sessions without any disturbances."
            </div>
          </div>

          <div className="studenttestimonial_card" id="3">
            <div className="studenttestimonial_card_header">
              <img src="/images/testimonials/testimonial3.jpg" alt="" />
              <div className="studenttestimonial_card_subheader">
                <div className="studenttestimonial_card_name">Arshad</div>
                <div className="studenttestimonial_card_company">
                  Principal Recruiter
                  <br /> @24*7.ai
                </div>
              </div>

              <div>
                <a
                  href="https://www.linkedin.com/in/arshad-recruitment/"
                  target="_blank"
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="-5.5 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#0A66C2"
                      d="M0 8v16c0 1 1 2 3 2h17l1-2V8c0-1-1-2-3-2H1L0 8zm3 2 2-2 2 2-2 2-2-2zm5 13V13h3v1l3-1c2 0 4 1 4 4v7h-3l-1-1v-6l-1-1-2 2v6H8v-1zm-5 0V13h3v11H3v-1z"
                    />
                  </svg>
                </a>
              </div>
            </div>

            <div className="studenttestimonial_card_content">
              "I'm thankful to both Ashfaq Ahmed & Raghunandan Patre. It was
              great learning & enriching of skills. Of course one could see huge
              transformation if they apply the basic principles coached by these
              two Monks. Last but not the least, want to thank Yaswanth Lohar
              Veeravalli ✌"
            </div>
          </div>

          <div className="studenttestimonial_card" id="4">
            <div className="studenttestimonial_card_header">
              <img src="/images/testimonials/testimonial4.jpg" alt="" />
              <div className="studenttestimonial_card_subheader">
                <div className="studenttestimonial_card_name">Sneha</div>
                <div className="studenttestimonial_card_company">
                  Seniour Sourcer
                  <br /> @Thomson Reuters
                </div>
              </div>
              <div>
                <a href="https://www.linkedin.com/in/ria08/" target="_blank">
                  <svg
                    width="30"
                    height="30"
                    viewBox="-5.5 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#0A66C2"
                      d="M0 8v16c0 1 1 2 3 2h17l1-2V8c0-1-1-2-3-2H1L0 8zm3 2 2-2 2 2-2 2-2-2zm5 13V13h3v1l3-1c2 0 4 1 4 4v7h-3l-1-1v-6l-1-1-2 2v6H8v-1zm-5 0V13h3v11H3v-1z"
                    />
                  </svg>
                </a>
              </div>
            </div>

            <div className="studenttestimonial_card_content">
              "It was incredible Ashfaq! I must thank you that its post your
              courses I was able to make the cut & get my first job in Canada,
              which is not so easy as they always look for Canadian experience.
              Your hack & techniques are super helpful."
            </div>
          </div>

          <div className="studenttestimonial_card" id="5">
            <div className="studenttestimonial_card_header">
              <img src="/images/testimonials/testimonial5.jpg" alt="" />
              <div className="studenttestimonial_card_subheader">
                <div className="studenttestimonial_card_name">Piyush Naik</div>
                <div className="studenttestimonial_card_company">
                  Associate Director
                  <br /> @Annalect India
                </div>
              </div>
              <div>
                <a
                  href="https://www.linkedin.com/in/piyushnaikphd/"
                  target="_blank"
                >
                  <svg
                    width="30"
                    height="30"
                    viewBox="-5.5 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="#0A66C2"
                      d="M0 8v16c0 1 1 2 3 2h17l1-2V8c0-1-1-2-3-2H1L0 8zm3 2 2-2 2 2-2 2-2-2zm5 13V13h3v1l3-1c2 0 4 1 4 4v7h-3l-1-1v-6l-1-1-2 2v6H8v-1zm-5 0V13h3v11H3v-1z"
                    />
                  </svg>
                </a>
              </div>
            </div>

            <div className="studenttestimonial_card_content">
              "Entire Monk team is super amazing & pretty supportive. For
              someone who comes from a non-tech background, they ensured that I
              not just understood tech, but gave me confidence + right
              techniques to engage with top techies. What I’ve learnt here will
              come with me life long."
            </div>
          </div>
        </div>
      </div>

      <div className="studenttestimonials_buttons">
        <div
          className="svg-container"
          dangerouslySetInnerHTML={{ __html: svg }}
          onClick={() => scrollToSection("1")}
        ></div>
        <div
          className="svg-container"
          dangerouslySetInnerHTML={{ __html: svg }}
          onClick={() => scrollToSection("2")}
        ></div>
        <div
          className="svg-container"
          dangerouslySetInnerHTML={{ __html: svg }}
          onClick={() => scrollToSection("3")}
        ></div>
        <div
          className="svg-container"
          dangerouslySetInnerHTML={{ __html: svg }}
          onClick={() => scrollToSection("4")}
        ></div>
        <div
          className="svg-container"
          dangerouslySetInnerHTML={{ __html: svg }}
          onClick={() => scrollToSection("5")}
        ></div>
      </div>
    </div>
  );
};

export default StudentTestimonials;
