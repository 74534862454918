import React from "react";
import HeaderCourse from "../../component/mini_diploma_component/HeaderCourse";
import LearningInPrograme from "../../component/mini_diploma_component/LearningInPrograme";
import StudentTestimonials from "../../component/mini_diploma_component/StudentTestimonials";
import WorkshopFor from "../../component/mini_diploma_component/WorkshopFor";
import YourTrainer from "../../component/mini_diploma_component/YourTrainer";
import Brands from "../../component/mini_diploma_component/Brands";
import VideoTestimonials from "../../component/mini_diploma_component/VideoTestimonials";
import LatestMentors from "../../component/mini_diploma_component/LatestMentors";
import CheckKnowledge from "../../component/mini_diploma_component/CheckKnowledge";
import FAQ from "../../component/mini_diploma_component/FAQ";
import Header from "../../component/global/Header";
import Footer from "../../component/global/Footer";

const MiniDiploma = () => {
  return (
    <>
      <Header />
      <HeaderCourse />
      <LearningInPrograme />
      <StudentTestimonials />
      <WorkshopFor />
      <YourTrainer />
      <Brands />
      <VideoTestimonials />
      <LatestMentors />
      <CheckKnowledge />
      <FAQ />
      <Footer />
    </>
  );
};

export default MiniDiploma;
